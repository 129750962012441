<template>
  <footer>
    <span>Developed by <a href="https://github.com/Claudio-DiGiovanni/">Claudio Di Giovanni</a></span>
  </footer>
</template>

<script>
export default {

};
</script>

<style>
footer{
  background-color: black;
  color: white;
  padding: 2rem;
  font-size: 15px;
}

a{
  text-decoration: none;
  cursor: pointer;
}
</style>
