<!-- eslint-disable max-len -->
<template>
  <div id="location">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <h2>La location</h2>
          <p>Il matrimonio si svolgerà nella fantastica location di Tenuta La Passera a Vergiate(VA), Cascina Passera 8
          </p>
          <a class="btn btn-secondary my-5"
            href="https://www.google.it/maps/place/Cascina+Passera,+8,+21029+Vergiate+VA/@45.7317898,8.6677801,17z/data=!3m1!4b1!4m5!3m4!1s0x47867b2c1f2800e3:0x402aa485d7898d09!8m2!3d45.7317861!4d8.6699688">Clicca
            qui per vedere la posizione</a>
        </div>
        <div class="col-12 col-md-6">
          <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src="https://th.bing.com/th/id/R.88092bbaa0797985a44eb5b54b37d6b2?rik=XTdDmzTSdeVztQ&riu=http%3a%2f%2fwww.residenzedepoca.it%2finc%2ffiles%2fimages%2fpartner%2flombardia%2fvarese%2ftenuta_la_passera_2086.jpg&ehk=UFI6hVse19OZOwRb%2fDmCgVe1lpRJqCg4aD9q8hKiSGc%3d&risl=&pid=ImgRaw&r=0"
                  class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img
                  src="https://www.ilgiardinodeidesideri.info/wp-content/uploads/2021/04/PHOTO-2021-04-05-17-57-33-19.jpg"
                  class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img src="https://cdn0.matrimonio.com/vendor/3783/3_2/1920/jpg/img-9046_2_93783-162566269532775.jpeg"
                  class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img
                  src="https://th.bing.com/th/id/R.2dfb06fdb09876ff14974356ff1fe642?rik=31szHrHPdTBjew&riu=http%3a%2f%2fwww.residenzedepoca.it%2finc%2ffiles%2fimages%2fpartner%2flombardia%2fvarese%2ftenuta_la_passera_2075.jpg&ehk=ip2kpnx%2bAz%2be%2fGmUg5UZK9r0q3vxu3c0mmxJDbRabqg%3d&risl=&pid=ImgRaw&r=0"
                  class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img
                  src="https://th.bing.com/th/id/R.f684ffa61d8aff13b51b31a1d5cb02e8?rik=%2bb38Rj7YLEvLtA&pid=ImgRaw&r=0"
                  class="d-block w-100" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade"
              data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"
              data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@300;400;500;600;700;800;900&display=swap');

#location {
  background-color: #5c1923;
  padding: 3rem 0;
  color: white;
  font-family: 'Merienda', cursive;
}
</style>
